import tw from "tailwind-styled-components";

export const FaqsQuestionWrapper = tw.div`
  border-b
  border-gray-200
  pb-6

  desktop:pb-4
`;

export const FaqsQuestionTitleWrapper = tw.li`
  list-none
  flex
  flex-row
  justify-between
  items-center
  text-base
  cursor-pointer
`;

export const FaqsQuestionTitle = tw.p`
  pr-4
`;

export const FaqsQuestionAnwer = tw.div`
  hidden
  mt-2
  text-base

  ${({ $isOpen }) => $isOpen && "block"}
`;
