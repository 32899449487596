import { useState } from "react";
import { ChevronUp, ChevronDown } from "../../../lib/icons";
import {
  FaqsQuestionTitleWrapper,
  FaqsQuestionWrapper,
  FaqsQuestionTitle,
  FaqsQuestionAnwer,
} from "./styles";
import EditorJSContent from "../../editor-js-content";

export const FaqsQuestion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FaqsQuestionWrapper>
      {question && (
        <FaqsQuestionTitleWrapper onClick={() => setIsOpen(!isOpen)}>
          <FaqsQuestionTitle>{question}</FaqsQuestionTitle>
          {isOpen ? ChevronUp : ChevronDown}
        </FaqsQuestionTitleWrapper>
      )}
      {answer && (
        <FaqsQuestionAnwer $isOpen={isOpen}>
          <EditorJSContent content={answer} />
        </FaqsQuestionAnwer>
      )}
    </FaqsQuestionWrapper>
  );
};

export default FaqsQuestion;
